<template>
    <UCard style="text-align: center;" class="h-full w-full">
        <template #header>
            Giriş Yap
        </template>
        <Login/>
    </UCard>
</template>
<style></style>

<script setup lang="ts">
const {getSession} = useAuth()

definePageMeta({
    auth: {
        unauthenticatedOnly: true,
        navigateAuthenticatedTo: '/students',
    },
    layout:"login"
})


onMounted(async () => {
    console.log(await getSession())
})

</script>